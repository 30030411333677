import React, { useState, createContext } from 'react';

const IndustrySearchContext = createContext();

export function IndustrySearchProvider({ children }) {
  const [searchTerm, setSearchTerm] = useState(null);
  const [industries, setIndustries] = useState([]);
  const [filteredIndustries, setFilteredIndustries] = useState([]);

  //Adds a value to each object that keeps the title, description, and any tags set in sanity as one big string that the searchTerm is compared
  //and matched to for filtering. Further sorts by the primary 7 industries from home mosaic sorted by size, then secondary 7 sorted by size, then the rest alphabetically
  const setDefault = (industriesData) => {
    const industryCards = industriesData
      .filter((industry) => !industry.node.noindex)
      .map((industry) => ({
        ...industry,
        searchParams: `${industry.node.title}
			 ${industry.node._rawSynopsis[0].children[0].text}
			 ${industry.node.tags.join(', ')}`.toLowerCase(),
      }));

    setIndustries(industryCards);
    setFilteredIndustries(
      industryCards.filter(
        (industry) =>
          industry.node._id !== '8b0e129a-e45f-4546-809e-e025b86ebae1'
      )
    );
  };

  //onkeyup sets a new state based on matched search filtering for other industries when
  //there is a search query, but nothing matches
  const handleChange = (e, industries, industriesData) => {
    const { value } = e.target;
    setSearchTerm(value);
    if (value) {
      let newIndustries = industries
        .filter((industry) => !industry.node.noindex)
        .filter((industry) => industry.searchParams.match(value.toLowerCase()));

      // Set to an empty array so that the Other Industries card does not display
      if (!newIndustries.length) {
        setFilteredIndustries(
          // industries.filter(
          // 	(industry) =>
          // 		industry.node._id === '8b0e129a-e45f-4546-809e-e025b86ebae1'
          // )
          []
        );
      } else {
        setFilteredIndustries(
          newIndustries
            .filter((industry) => !industry.node.noindex)
            .filter(
              (industry) =>
                industry.node._id !== '8b0e129a-e45f-4546-809e-e025b86ebae1'
            )
        );
      }
    } else {
      setDefault(industriesData);
    }
  };

  return (
    <IndustrySearchContext.Provider
      value={{
        industries,
        setSearchTerm,
        setIndustries,
        filteredIndustries,
        setFilteredIndustries,
        setDefault,
        handleChange,
      }}
    >
      {children}
    </IndustrySearchContext.Provider>
  );
}

export default IndustrySearchContext;
